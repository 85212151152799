// Note : fichier séparé de regimeListe pour l'instant, car regimeListe.js utilise des require() qui posent pb dans les tests
export const IDS_REGIMES_ALIMENTAIRES = {
  ACTUEL: 0,
  MOINS_25_POURCENTS: 1,
  MOINS_50_POURCENTS: 2,
  VEGETARIEN: 3,
  PERSONNALISE: -1,
  VEGETALIEN: 4,
  TYFA: 5,
};
