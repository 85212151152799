// Note : fichier séparé de regimeListe pour l'instant, car regimeListe.js utilise des require() qui posent pb dans les tests
export const IDS_CATEGORIES_REGIMES_ALIMENTAIRES = {
  CEREALES: 1,
  FRUITS: 2,
  OLEAGINEUX: 3,
  HUILE: 4,
  LEGUMES: 5,
  LEGUMINEUSES: 6,
  OEUFS: 7,
  PATATE: 8,
  PRODUITS_LAITIERS: 9,
  SUCRE: 10,
  VIANDE_ROUGE: 11,
  VIANDE_BLANCHE: 12,
};
