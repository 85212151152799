<template>
  <img
    alt="Fleche Bouton Vert"
    class="fleche-bouton-vert"
    :src="require('@/assets/img/fleches/fleche-bouton-vert.svg')"
    width="15"
    height="15"
  />
</template>

<script></script>
